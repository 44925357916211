import styled from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';

export const Card = styled.article`
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  padding: ${saasModernTheme.space[8]}px;
  box-sizing: content-box;
  border: 1px solid #aaa;
  @media (max-width: 450px) {
    padding: ${saasModernTheme.space[5]}px;
  }
`;
