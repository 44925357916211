import React from 'react';
import { Title } from 'common/components/Typography/Title/title.style';
import { Description } from 'common/components/Typography/Description/description.style';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import {
  SectionWhySwiftask,
  HeaderWhySwiftask,
  ContentHeader,
  Cloud,
  ParentCard,
  ChildCard,
  ContentCard,
  ContentAside,
  ContentElement,
  Rectangle1,
  Rectangle2,
  Rectangle3,
  Rectangle4,
  Rectangle5,
  Rectangle6,
  Rectangle7,
  Cloud2,
} from './whySwiftask.style';
import Container from 'common/components/Container';
import CardBGblur from 'common/components/Card/CardBGblur';
import { PrismicRichText } from 'common/components/PrismicRichText/PrismicRichText';
import NextImage from 'common/components/NextImage';

const WhySwiftaskSection = ({ slice }) => {

  return (
    <SectionWhySwiftask>
      <Cloud />
      <HeaderWhySwiftask>
        <ContentHeader>
          <Title data-aos="zoom-in" as="h2">
            {slice.primary.title}
          </Title>
          <Description data-aos="zoom-in">
            <PrismicRichText field={slice.primary.subtitle} />
          </Description>
          <BtnGetStarted label={slice.primary.ctatext} />
        </ContentHeader>
      </HeaderWhySwiftask>

      <Container>
        <Rectangle1 />
        <Rectangle2 />
        <Rectangle3 />
        <Rectangle4 />
        <Rectangle5 />
        <Rectangle6 />
        <Rectangle7 />
        <Cloud2 />
        <ParentCard>
          {slice.items.map((item, index) => (
            <ChildCard
              align={index % 2 === 0 ? 'flex-start' : 'flex-end'}
              key={index}
              data-aos="zoom-in"
            >
              <CardBGblur>
                <ContentCard
              hasImage={Object.keys(item.image).length > 0}
                >
                  {
                    Object.keys(item.image).length > 0? 
                    <ContentAside>
                    <NextImage field={item.image} fill={true} />
                  </ContentAside> :null
                  }
                  <ContentElement>
                    <Title color="#f6ae2d" fontWeight={900} as="h3">
                      {item.title}
                    </Title>
                    <Description>
                      <PrismicRichText field={item.description} />
                    </Description>
                  </ContentElement>
                </ContentCard>
              </CardBGblur>
            </ChildCard>
          ))}
        </ParentCard>
      </Container>
    </SectionWhySwiftask>
  );
};

export default WhySwiftaskSection;
